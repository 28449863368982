import "./import/modules";
import "./import/components";

$(function() {
	$(window).on("load", function () {
		$('.slider').owlCarousel({
			loop:true,
			nav:true,
			margin:30,
			items:1,
			autoplay: false,
			autoplayTimeout: 5000,
			dots:true
		});
		window.dispatchEvent(new Event('resize'));
		$('.slider .owl-nav').css('width', $('.slider .owl-dots').width()+50+'px');
	});
});